.selected-data {
    margin-top: 20px;
    padding: 20px;
    background-color: rgba(191, 191, 191, 0.15);
  }
  
  .selected-data .caption {
    font-weight: bold;
    font-size: 115%;
    margin-right: 4px;
  }